import {Message} from 'element-ui'

export function getNews() {
    return Get("/officialWebsite/news/list/all")
}

export function getPosition() {
  return Get("/officialWebsite/recruit/list/all")
}

export function getHrInfo() {
  return Get("/officialWebsite/hr/one")
}

export function setFeedback(data) {
  return Post("/officialWebsite/feedback/add", data)
}

async function Get(url,data={}) {
  let dataStr = ''; //数据拼接字符串
  Object.keys(data).forEach(key => {
    dataStr += key + '=' + data[key] + '&';
  })
  url = 'api/admin/common'+ url;
  if (dataStr !== '') {
    dataStr = dataStr.substr(0, dataStr.lastIndexOf('&'));
    url = url + '?' + dataStr;
  }
  try{
    let response = await fetch(url,{
      method:'get',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Cache-Control': ' no-cache'
      }
    });
    let json = await response.json();
    if (json.status !== 200) {
      throw {errorMsssage:json.message};
    }
    return json
  } catch(e) {
    let str = e.errorMsssage || '服务器错误'
    Message.error(str);
    throw 'http error';
  }
}

async function Post(url,data={}) {
  url = 'api/admin/common'+ url;
  try{
    let response = await fetch(url,{
      body: JSON.stringify(data),
      method:'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Cache-Control': ' no-cache'
      }
    });
    let json = await response.json();
    if (json.status !== 200) {
      throw {errorMsssage:json.message};
    }
    return json
  } catch(e) {
    let str = e.errorMsssage || '服务器错误'
    Message.error(str);
    throw 'http error';
  }
}